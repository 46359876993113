<!--
 * @Description: 全部商家页面组件
 -->
<template>
  <div class="goods">
    <!-- 搜索及分页 -->
    <el-card class="query" body-style="padding: 0 0;">
      <el-row class="queryCondition">
        <el-col
          :offset="12"
          :span="12"
          style="height: 40px; line-height: 40px"
          class="pagination"
        >
          <!-- 分页 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :current-page="query.page"
            @current-change="currentChange"
          ></el-pagination>
          <!-- 分页END -->
        </el-col>
      </el-row>
    </el-card>
    <!-- 搜索及分页END -->

    <!-- 主要内容区 -->
    <div class="main" v-loading="loading" element-loading-text="加载中">
      <div class="list" v-if="list.length > 0">
        <el-card
          shadow="always"
          body-style="padding: 15px 0 15px 20px"
          v-for="(item, k) in list"
          :key="k"
          style="margin-bottom: 12px"
        >
          <el-row>
            <el-col :span="10" style="font-size: 12px">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  height: 38px;
                  padding-bottom: 9px;
                  border-bottom: 1px solid #e4e4e4;
                "
              >
                <div style="width: 60%">
                  <div style="font-size: 18px; font-weight: bold">
                    {{ item.CName
                    }}<span class="shopYear">{{ item.comeInYear }}年店</span>
                  </div>
                  <div>{{ item.Addr }}</div>
                </div>
                <div
                  style="
                    height: 30px;
                    width: 40%;
                    text-align: center;
                    padding: 5px 20px 0 0;
                  "
                >
                  <!-- todo -->
                  <!-- <span>二维码</span> -->
                  <span class="daifa">代</span>
                </div>
              </div>
              <div
                style="
                  padding-top: 9px;
                  border-bottom: 1px solid #e4e4e4;
                  color: #707070;
                "
              >
                <el-row>
                  <el-col :span="12">
                    <div class="shopInfoItem">地址：{{ item.Addr }}</div>
                    <div class="shopInfoItem">
                      目前有货：{{ item.totalGoods }}款
                    </div>
                    <div class="shopInfoItem">联系电话：{{ item.Mobile }}</div>
                  </el-col>
                  <el-col :span="12">
                    <div class="shopInfoItem">主打产品：</div>
                    <div class="shopInfoItem">QQ：{{ item.QQ }}</div>
                    <div class="shopInfoItem">微信：{{ item.Winxin }}</div>
                  </el-col>
                </el-row>
              </div>
              <div style="color: #707070; font-size: 12px">
                <el-row style="text-align: center">
                  <el-col
                    :span="8"
                    style="margin-top: 9px; border-right: 1px solid #e9e9e9"
                  >
                    <div class="shopSaleItem">年销量</div>
                    <div class="shopSaleItem">{{ item.saleCount }}件</div>
                    <div>
                      <router-link
                        :to="{
                          path: '/shop',
                          query: { SID: item.ID },
                        }"
                        target="_blank"
                      >
                        <el-button
                          type="danger"
                          plain
                          size="small"
                          style="width: 120px"
                          >进入查看</el-button
                        >
                      </router-link>
                    </div>
                  </el-col>
                  <el-col
                    :span="8"
                    style="margin-top: 9px; border-right: 1px solid #e9e9e9"
                  >
                    <div class="shopSaleItem">
                      退货率<span style="color: #2ea067">0-5%</span>
                    </div>
                    <div class="shopSaleItem">
                      好于<span style="background: #2ea067; color: #fff"
                        >49%</span
                      >的同行
                    </div>
                    <div>
                      <el-button
                        type="primary"
                        plain
                        size="small"
                        style="width: 120px"
                        >收藏商家</el-button
                      >
                    </div>
                  </el-col>
                  <el-col
                    :span="8"
                    style="margin-top: 9px; border-right: 1px solid #e9e9e9"
                  >
                    <div class="shopSaleItem">
                      缺货率<span style="color: #2ea067">0-5%</span>
                    </div>
                    <div class="shopSaleItem">
                      好于<span style="background: #2ea067; color: #fff"
                        >58%</span
                      >的同行
                    </div>
                    <div>
                      <el-button
                        type="warning"
                        plain
                        size="small"
                        style="width: 120px"
                        >查看联系方式</el-button
                      >
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="14">
              <el-carousel
                :interval="5000"
                arrow="always"
                height="220px"
                v-if="item.goods.length > 4"
              >
                <el-carousel-item v-for="i in [0, 1]" :key="i">
                  <el-row>
                    <el-col
                      :span="6"
                      v-for="(goods, k) in item.groupGoodsList[i]"
                      :key="k"
                      class="shopGoods"
                    >
                      <router-link
                        :to="{
                          path: '/detail',
                          query: { id: goods.ID },
                        }"
                        target="_blank"
                      >
                        <el-image
                          :src="
                            $target + '/HB/UpLoad/' + goods.Path + '/' + goods.FName
                          "
                          style="width: 160px; height: 160px"
                        ></el-image>
                        <div style="margin-top: 3px">{{ goods.PName }}</div>
                        <div style="margin-top: 7px; color: #df454e">
                          点击量：{{ goods.HotNum }}
                        </div>
                      </router-link>
                    </el-col>
                  </el-row>
                </el-carousel-item>
              </el-carousel>
              <el-row v-else>
                <el-col
                  :span="6"
                  v-for="(goods, k) in item.goods"
                  :key="k"
                  class="shopGoods"
                >
                  <router-link
                    :to="{
                      path: '/detail',
                      query: { id: goods.ID },
                    }"
                    target="_blank"
                  >
                    <el-image
                      :src="$target + '/HB/UpLoad/' + goods.Path + '/' + goods.FName"
                      style="width: 160px; height: 160px"
                    ></el-image>
                    <div style="margin-top: 3px">{{ goods.PName }}</div>
                    <div style="margin-top: 7px; color: #df454e">
                      点击量：{{ goods.HotNum }}
                    </div>
                  </router-link>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <div v-else class="none-product">无数据</div>
      <!-- 底部分页 -->
      <div style="text-align: center">
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="query.page"
          @current-change="currentChange"
        ></el-pagination>
        <!-- 分页END -->
      </div>
    </div>
    <!-- 主要内容区END -->
  </div>
</template>
<script>
import { getYearsBetween } from '@/util/common'

export default {
  name: "ShopList",
  components: {},
  data() {
    return {
      dateRange: "",
      loading: true,
      list: [], // 商品列表
      total: 50, // 商品总量
      currentPage: 1, //当前页码
      query: {
        page: 1,
        recommend: 1,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    /**
     * 变更页码
     * @param {*} currentPage
     */
    currentChange(currentPage) {
      this.loading = true;
      this.query.page = currentPage;
      this.getData();
    },
    /**
     * 获取列表数据
     */
    async getData() {
      const queryString = new URLSearchParams(
        Object.entries(this.query)
      ).toString();
      const { data } = await this.$axios.get(
        this.$apiBaseUrl + "/web/supply/list?" + queryString
      );
      const list = data.data.list.map((v) => {
        let groupGoods = [];
        if (v.goods && v.goods.length > 4) {
          groupGoods.push(v.goods.slice(0, 4));
          groupGoods.push(v.goods.slice(4));
        }
        v.groupGoodsList = groupGoods;
        v.comeInYear = getYearsBetween(v.ITime)
        return v;
      });
      this.list = list;
      this.total = data.data.total;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.el-menu--horizontal {
  height: 50px;
  line-height: 50px;
}
.el-input__inner {
  border: 0 !important;
}
.el-menu--horizontal > .el-menu-item {
  margin: 10px 0;
  height: 30px !important;
  line-height: 30px !important;
  padding: 0 15px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  color: #fff;
  background: #fb4d4f;
  border: 1px solid #fb4d4f;
  border-radius: 1em;
}
.goods {
  background: rgb(246, 246, 246);
  padding: 15px 0;
}
/* 分类标签CSS */
.goods .allCategory {
  width: 1225px;
  font-size: 14px;
  margin: 0 auto;
}
.allCategory .category {
  line-height: 50px;
  height: 50px;
  border: 1px solid #e4e4e4;
}
.category .categoryLabel {
  text-align: center;
  background: rgb(246, 246, 246);
  color: #565656;
}
/* 分类标签CSS END */

/* 查询 */
.goods .query {
  width: 1225px;
  margin: 0 auto;
}
.query .queryCondition {
  border: 0.5px solid #e4e4e4;
}
.query .queryCondition .queryTag {
  border-right: 1px solid #e4e4e4;
  text-align: center;
  background: #fff;
}
.query .pagination {
  height: 30px;
  line-height: 30px;
  padding: 5px 0;
  text-align: right;
}
/* 查询END */
/* 主要内容区CSS */
.goods .main {
  margin: 0 auto;
  max-width: 1225px;
}
.goods .main .list {
  min-height: 650px;
  overflow: auto;
}
.main .list::-webkit-scrollbar {
  width: 0;
  display: none;
}
.goods .main .none-product {
  color: #333;
  margin-left: 13.7px;
}
.shopYear {
  font-size: 12px;
  color: #783400;
  padding: 1px 3px;
  font-family: SimSun;
  vertical-align: top;
  background: #fbde2d;
  border: 1px solid #e3c71c;
  line-height: 24px;
  margin-left: 5px;
  height: 24px;
  font-weight: normal;
}
.shopInfoItem {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 9px;
}
.shopSaleItem {
  margin-bottom: 5px;
  line-height: 18px;
  height: 18px;
}
.daifa {
  font-size: 12px;
  background: #9e51ed;
  color: #fff;
  width: 20px;
  height: 22px;
  line-height: 22px;
  border-radius: 2px;
  vertical-align: top;
  text-align: center;
  margin-left: 10px;
  padding: 3px 3px;
}
.shopGoods {
  padding: 8px 5px;
  border: 1px solid #e4e4e4;
  font-size: 12px;
  color: #636363;
  text-align: center;
  cursor: pointer;
}
/* 主要内容区CSS END */
</style>
